import axios from 'axios';
import { create as createStore } from 'zustand';
import { keyBy } from '../utils';
import { useMemo } from 'react';
import { MODEL } from '../constants/models';

export const useAppStore = createStore((set, get) => {
  return {
    profile: null,
    establishment: null,

    selectedCompany: null,
    showAllCompanies: false,

    locale: {
      lang: localStorage.getItem('lang') ?? 'es',
      captions: null,
    },

    isDataLoaded: false,
    appMenu: false,
    isMobile: false,
    socket: null,

    upsert(key, newData) {
      const oldData = get()[key];
      const joinedData = { ...keyBy(oldData, '_id'), ...keyBy(newData, '_id') };
      set({ [key]: Object.values(joinedData) });
    },

    delete(key, ids) {
      const oldData = get()[key] ?? [];
      if (Array.isArray(ids)) {
        set({ [key]: oldData.filter((x) => !ids.includes(x._id)) });
      } else {
        set({ [key]: oldData.filter((x) => x._id !== ids) });
      }
    },

    findById(key, id) {
      const data = get()[key];

      if (!Array.isArray(data)) {
        return null;
      }

      return data.find((item) => item._id === id);
    },

    async getByIdRequest(key, id) {
      const upsert = get().upsert;
      const data = await axios
        .get(`/${key}/${id}`)
        .then((x) => x.data)
        .catch(() => null);

      if (data) {
        upsert(key, data);
      }

      return data;
    },

    async createRequest(key, obj) {
      const upsert = get().upsert;
      const output = await axios
        .post(`/${key}`, obj)
        .then(({ data }) => data)
        .catch(() => null);

      if (output) {
        upsert(key, output);
      }

      return output;
    },

    async updateByIdRequest(key, obj) {
      const upsert = get().upsert;
      const data = await axios
        .put(`/${key}/${obj._id}`, obj)
        .then((x) => x.data)
        .catch(() => null);

      if (data) {
        upsert(key, data);
      }

      return data;
    },

    async updateRequest(key, obj) {
      const data = await axios
        .put(`/${key}`, obj)
        .then((x) => x.data)
        .catch(() => null);

      if (data) {
        set({ [key]: data });
      }

      return data;
    },

    async getAllRequest(key, filter) {
      const { showAllCompanies, upsert } = get();

      const output = await axios
        .get(`/${key}`, {
          params: {
            filter,
            showAll: showAllCompanies,
          },
        })
        .then(({ data }) => {
          if ('data' in data && Array.isArray(data.data)) {
            return data.data;
          }
          return data;
        })
        .catch(() => null);

      if (Array.isArray(output)) {
        if (output) {
          upsert(key, output);
        }
      } else {
        set({ [key]: output });
      }

      return output;
    },

    async deleteRequest(key, ids) {
      const storeDelete = get().delete;
      let result = false;
      if (Array.isArray(ids)) {
        result = await axios
          .delete(`/${key}`, { data: ids })
          .then(() => true)
          .catch(() => false);
      } else {
        result = await axios
          .delete(`/${key}/${ids}`)
          .then(() => true)
          .catch(() => false);
      }

      if (result) {
        storeDelete(key, ids);
      }

      return result;
    },

    set,
    get,
  };
});

export const useFindById = () => {
  return useAppStore((s) => s.findById);
};

export const useAppDataSource = (key) => {
  const data = useAppStore((s) => s[key]);
  return useMemo(() => {
    return data ?? [];
  }, [data]);
};

export const useSupervisingUsers = () => {
  const profile = useProfile();

  const supervisors = useAppDataSource(MODEL.SUPERVISOR);

  return useMemo(() => {
    return [...new Set(supervisors.filter((x) => x.supervisor === profile?._id).flatMap((x) => x.users)).keys()];
  }, [supervisors, profile]);
};

export const useProfile = () => {
  return useAppStore((s) => s[MODEL.PROFILE]);
};

export const useCountries = () => {
  const countries = useAppStore((s) => s[MODEL.COUNTRIES]);
  return useMemo(() => {
    return countries ?? [];
  }, [countries]);
};
