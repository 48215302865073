import { DropDownButton } from 'devextreme-react';
import { setCookie } from '../../utils';
import { useIsMobile } from '../../utils/hooks';
import { useProfile } from '../../store';

export const ProfileButton = () => {
  const profile = useProfile();
  const isMobile = useIsMobile();

  const logout = () => {
    setCookie('remember', false);
    window.location.reload();
  };

  if (isMobile || !profile) {
    return <></>;
  }

  const dropdownItems = [
    // { key: 'profile', label: 'Ajustes', icon: 'preferences', onClick: goToProfile },
    { key: 'logout', label: 'Cerrar sesión', icon: 'runner', onClick: logout },
  ];

  return (
    <DropDownButton
      text={profile?.name ?? 'Mi cuenta'}
      icon="user"
      displayExpr={'label'}
      stylingMode="text"
      className="white-text"
      dropDownOptions={dropdownOptions}
      items={dropdownItems}
    />
  );
};

const dropdownOptions = { width: '150px' };
