export const MODEL = {
  PROFILE: 'user/profile',
  EXPENSE: 'expense',
  EXPENSE_SHEET: 'expenseSheet',
  ESTABLISHMENT: 'establishment',
  ESTABLISHMENT_INFO: 'establishmentInfo',
  ESTABLISHMENT_SETTINGS: 'establishment/settings',
  USER: 'users',
  PROJECT: 'project',
  CURRENCY: 'currencyCode',
  TRAVEL: 'travel',
  HOLIDAY: 'holiday',
  SIGNUP: 'singup',
  BUSINESS: 'business',
  PROVIDER: 'provider',
  PAYMENT_METHOD: 'paymentMethod',
  TAG: 'tag',
  SUPERVISOR: 'supervisor',
  SETTLEMENT_TEMPLATE: 'settlementtemplate',
  COMPANY: 'company',
  SUB_COMPANY: 'subcompany',
  TASK: 'task',
  GROUP: 'group',
  EMAIL_TEMPLATE: 'emailTemplate',

  /**
   * BC
   */
  POST_CODE: 'bc/postCode',
  COUNTRIES: 'bc/countries',
};
