import { ProfileButton } from './ProfileButton';
import { useCurrentPathTitle } from '../../utils/hooks';
import { AppHeaderWrapper } from '../styled';

export const AppHeader = () => {
  const title = useCurrentPathTitle();

  return (
    <AppHeaderWrapper>
      <h2>{title}</h2>
      <ProfileButton />
    </AppHeaderWrapper>
  );
};
