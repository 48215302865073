import { lazy } from 'react';
import { ROLE } from './api';

export const AppRoutes = {
  '/expenses': {
    name: 'Gastos',
    Component: lazy(() => import('../pages/Expenses')),
  },
  '/expenseSheets': {
    name: 'Hojas de gastos',
    Component: lazy(() => import('../pages/ExpenseSheets')),
  },
  '/projects': {
    name: 'Proyectos',
    Component: lazy(() => import('../pages/Projects')),
  },
  '/businesses': {
    name: 'Clientes',
    Component: lazy(() => import('../pages/Businesses')),
  },
  '/providers': {
    name: 'Proveedores',
    Component: lazy(() => import('../pages/Providers')),
  },
  '/paymentMethods': {
    name: 'Métodos de pago',
    Component: lazy(() => import('../pages/PaymentMethods')),
  },
  '/tags': {
    name: 'Tags',
    Component: lazy(() => import('../pages/Tags')),
  },
  '/currencyCodes': {
    name: 'Divisas',
    Component: lazy(() => import('../pages/CurrencyCodes')),
  },
  '/vacationControl': {
    name: 'Control de vacaciones',
    Component: lazy(() => import('../pages/HolidayControl')),
  },
  '/travelControl': {
    name: 'Control de viajes',
    Component: lazy(() => import('../pages/TravelControl')),
  },
  '/signUps': {
    name: 'Control de accesos',
    Component: lazy(() => import('../pages/SignUpControl')),
  },
  '/emailTemplate': {
    name: 'Plantillas de correo',
    roles: [ROLE.S_ADMIN],
    Component: lazy(() => import('../pages/EmailTemplates')),
  },
  '/supervisors': {
    name: 'Supervisores',
    roles: [ROLE.S_ADMIN],
    Component: lazy(() => import('../pages/Supervisors')),
  },
  '/users': {
    name: 'Usuarios',
    roles: [ROLE.S_ADMIN],
    Component: lazy(() => import('../pages/Users')),
  },
  '/centers': {
    name: 'Centros de trabajo',
    roles: [ROLE.S_ADMIN],
    Component: lazy(() => import('../pages/Centers')),
  },
  '/subcompanies': {
    name: 'Unidades de negocio',
    roles: [ROLE.S_ADMIN],
    Component: lazy(() => import('../pages/SubCompanies')),
  },
  '/settings': {
    name: 'Ajustes',
    roles: [ROLE.S_ADMIN],
    Component: lazy(() => import('../pages/Settings')),
  },
  '/groups': {
    name: 'Grupos',
    roles: [ROLE.S_ADMIN],
    Component: lazy(() => import('../pages/Groups')),
  },
};
