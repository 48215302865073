import { Navigate, Route, Routes } from 'react-router';
import { Layout } from './components/Layout';
import { useIsMobile } from './utils/hooks';
import { locale, loadMessages } from 'devextreme/localization';
import esMessages from 'devextreme/localization/messages/es.json';
import { ROLE } from './constants/api';
import { useProfile, useSupervisingUsers } from './store';
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import { AppRoutes } from './constants';
import { lazy } from 'react';

const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const ExpenseSheetPreview = lazy(() => import('./pages/ExpenseSheetPreview'));

dayjs.locale('es');
loadMessages(esMessages);
locale('es');

const App = () => {
  const profile = useProfile();
  const isMobile = useIsMobile();
  const supervisorUsers = useSupervisingUsers();

  const hasPermission = [ROLE.S_ADMIN, ROLE.ADMIN].includes(profile?.role);
  const isValid =
    hasPermission && profile && !isMobile && (Boolean(supervisorUsers.length) || ROLE.S_ADMIN === profile?.role);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={profile ? <Home /> : <Login />} />
        <Route path="/expenseSheets/preview/:id" element={<ExpenseSheetPreview />} />
        {isValid ? routes : null}
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    </Layout>
  );
};

export default App;

const routes = Object.entries(AppRoutes).map(([key, { Component }]) => {
  return <Route key={key} path={key} element={<Component />} />;
});
