import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import themes from 'devextreme/ui/themes';
import './index.css';
import axios from 'axios';
import { QWARK_API_URL } from './constants/api';
import { notification } from './utils';

themes.current(localStorage.getItem('dx-theme') || 'dx.light.kuara');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

axios.defaults.baseURL = QWARK_API_URL;
// axios.defaults.baseURL = 'https://4b22-83-46-74-208.eu.ngrok.io/';

axios.interceptors.response.use(undefined, (err) => {
  console.log(err?.response?.data);
  notification('warning', err?.response?.data, 2);

  return err;
});
