import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { AppRoutes } from '../constants';

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 768);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  return isMobile;
};

export const useCurrentPathTitle = () => {
  const { pathname } = useLocation();

  return AppRoutes[pathname]?.name ?? 'Qwark';
};
