import { MODEL } from './models';

// export const QWARK_API_URL = 'http://localhost:3005';
export const QWARK_API_URL = 'https://qwark-api.kuarasoftware.es';
export const KORE_API_URL = 'https://kore-api.kuarasoftware.es';
export const REPORTS_URL = 'https://reports.kuarasoftware.es/';
// export const REPORTS_URL = 'https://localhost:44347/';

export const ROLE = {
  S_ADMIN: '8fc02de6',
  ADMIN: 'a2470ee4',
  USER: '97f567cf',
};

export const ENDPOINTS = {
  [MODEL.USER]: {
    default: '/user',
    login: '/user/login',
    profile: '/user/profile',
  },
};
